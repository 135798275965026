// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Header from "../components/header"
import Front from "../components/front"
import Footer from "../components/footer"

import "../components/layout.css"
import dizBlog from "../images/dizBlog.png"

const Blog = ({data}) =>{
 
        

        console.log(data)
        
return(   
  <div className="font">
  <SEO title={data.allWordpressPost.edges[0].node.categories[0].name} />    
  <Header theme = "navbar1" />
  <div style={{
    top: '10%',
    height: '80px',
  }} ></div>
  <h1 id="cat"  style={{
          fontSize: '60px',
          textAlign: 'center',  
          margin: '50% 0% 0% 0% 0%', 
          color: 'white',
          whiteSpace: 'nowrap'
  }} >{data.allWordpressPost.edges[0].node.categories[0].name}</h1>
  <div className="blogformat" >
  {data.allWordpressPost.edges.map(({ node }) =>(
              <div className="blog" key={node.slug}>
                      <Link className={(node.tags === null) ? "title" : (node.tags[0].name == "malayalam" )? "maltitle": " "} to={node.slug}>
                        <div>
                          <h2>{node.title}</h2>
                          <Link className="authndcat" to={node.author.name}><p>{node.author.name}</p></Link>
                          <Link className="authndcat" to={node.categories[0].name}><p>{node.categories[0].name}</p></Link>
                          <p>{node.date}</p>
                        </div>
                        <h4 dangerouslySetInnerHTML={{__html: node.excerpt}} ></h4>
                        <Img fluid={(node.featured_media === null )? data.black.childImageSharp.fluid : node.featured_media.localFile.childImageSharp.fluid}/>
                      </Link>          
              </div>    
          ))}
  </div>
   <Footer theme = "footer1" />
</div>      
        )
               
} 
export default Blog    

export const pageQuery = graphql`
        query ($name: String!) {
            allWordpressPost(filter: {categories: {elemMatch: {name: {eq: $name }}}}) {
              edges {
                node {
                  id
                  date(formatString: "DD/MM/YY")
                  title
                  slug
                  excerpt
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid (maxWidth:1000) {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                  author {
                    name
                    id
                  }
                  categories {
                    name
                    id
                  }
                  tags {
                    name
                  }
                }
              }
            }
            black: file(relativePath: {eq: "dribble first-01.jpg"}) {
              childImageSharp {
                fluid (maxWidth:1000) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }          
        `